import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import tulip from './assets/tulip.svg';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, Modal, Snackbar, Typography } from '@mui/material';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0d67b4',
    },
    error: {
      main: '#ffa49d',
    },
    background: {
      default: '#0d67b4',
    },
  },
});

function App() {
  const [contacting, setContacting] = useState(false);
  const submitContact = useCallback(async (values) => {
    try {
      const docRef = await addDoc(collection(db, 'mail'), {
        to: 'aaron@lale.io',
        message: {
          subject: 'New (lale.io) Contact Us Form',
          html: `
            <div style="text-align: center; padding: 32px; font-size: 16px">
              <h1>New Contact Us Form</h1>
              <div>Email: ${values.email}</div>
              <div>Phone: ${values.phone || 'No Phone Number'}</div>
              <div>How can we help you?: ${values.help}</div>
            </div>
          `,
        },
      });
      setContacting(false);
      setShowSuccess(true);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }, []);
  const [showSuccess, setShowSuccess] = useState(false);
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="page">
          <div className="centered">
            <div className="logo-box">
              <img style={{ width: 300 }} src={tulip} alt="lale.io" />
              <h1
                style={{
                  margin: 0,
                  fontSize: 48,
                  top: 0,
                  position: 'relative',
                }}
              >
                lale.io
              </h1>
            </div>
            <div
              style={{
                textAlign: 'center',
                maxWidth: 600,
                padding: '0px 20px',
              }}
            >
              <p>
                {`Lale.io is a company of software engineers for hire.  We can help you create a product or build your business by developing custom applications and technologies to suit your needs.`}
              </p>
              <p>{`If you can imagine it, we can build it.`}</p>
            </div>
            <div style={{ marginBottom: 16 }} />
            <Button onClick={() => setContacting(true)} variant="outlined">
              Contact Us
            </Button>
          </div>
        </div>
        <Modal open={contacting} onClose={() => setContacting(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              backgroundColor: 'secondary.main',
              textAlign: 'center',
              borderRadius: 1,
              padding: '32px 16px',
            }}
          >
            <h1 style={{ marginTop: 0, marginBottom: 8 }}>Contact Us</h1>
            {contacting && (
              <FormContainer
                defaultValues={{ email: '', phone: '', help: '' }}
                onSuccess={submitContact}
              >
                <div style={{ width: 300, margin: 'auto' }}>
                  <p>We will never share your info with a third party</p>
                </div>
                <TextFieldElement
                  style={{ width: 300, marginBottom: 32 }}
                  variant="standard"
                  name="email"
                  label="Email"
                  type="email"
                  required
                />
                <TextFieldElement
                  style={{ width: 300, marginBottom: 32 }}
                  variant="standard"
                  name="phone"
                  label="Phone Number (optional)"
                  type="tel"
                />
                <TextFieldElement
                  style={{ width: 300, marginBottom: 32 }}
                  variant="standard"
                  name="help"
                  label="How can we help you?"
                  type="text"
                  required
                  multiline
                  maxRows={6}
                />
                <div>
                  <Button type="submit" variant="outlined">
                    Submit
                  </Button>
                </div>
              </FormContainer>
            )}
          </Box>
        </Modal>
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => setShowSuccess(false)}
        >
          <Alert
            onClose={() => setShowSuccess(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {`Thank you!  We'll talk soon.`}
          </Alert>
        </Snackbar>
      </ThemeProvider>
      <style jsx>{`
        .page {
          // min-height: 100vh;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: #f1eee3;
          // background-color: #77bc3e;
          background-color: #0d67b4;
          // background-color: #e74e36;
          padding: 100px 0px;
        }
        .centered {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        p {
          font-size: 16px;
        }
        .logo-box {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
        }
        @media only screen and (max-width: 600px) {
          .logo-box {
            text-align: center;
          }
        }
      `}</style>
    </>
  );
}

export default App;
